import { FileList } from "./components/desktop/window/FileList";
import { Window } from "./components/desktop/window/Window";

const diskFiles = [
  { name: "Computer", icon: "computer" },
  { name: "Joystick", icon: "joystick" },
  { name: "Multimedia", icon: "multimedia" },
  { name: "Wordpad", icon: "wordpad" },
];

export const GamesWindow = (props) => {
  return (
    <Window {...props}>
      <FileList files={diskFiles} />
    </Window>
  );
};

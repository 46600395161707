import { useEffect } from 'react';
import drums909 from '../../assets/sounds/909-drums.mp3';
import useSound from 'use-sound';

const DrumKit = () => {
    const [play] = useSound(drums909, {
      sprite: {
        kick: [0, 350],
        hihat: [374, 160],
        snare: [666, 290],
        cowbell: [968, 200],
      }
    });
  
    useEffect(() => {
      const handleKeyDown = (event) => {
        switch(event.key) {
          case '1':
            play({ id: 'kick' });
            break;
          case '2':
            play({ id: 'hihat' });
            break;
          case '3':
            play({ id: 'snare' });
            break;
          case '4':
            play({ id: 'cowbell' });
            break;
          default:
            break;
        }
      };
      
      document.addEventListener('keydown', handleKeyDown);
      
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [play]);
  
    return (
      <div>
        <button
          aria-label="kick"
          onMouseDown={() => play({ id: 'kick' })}
        >
          1: Stopka
        </button>
        &nbsp;
        <button
          aria-label="hihat"
          onMouseDown={() => play({ id: 'hihat' })}
          >
          2: Hacik
        </button>
        &nbsp;
        <button
          aria-label="snare"
          onMouseDown={() => play({ id: 'snare' })}
          >
          3: Werbelek
        </button>
        &nbsp;
        <button
          aria-label="cowbell"
          onMouseDown={() => play({ id: 'cowbell' })}
        >
          4: Krówka
        </button>
      </div>
    );
  }

  export default DrumKit;
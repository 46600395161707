export const Window = ({ title, children, onClose, ...props }) => (
  <div className="window window-default" data-window={true} {...props}>
    <div className="window-inside">
      <WindowNav
        title={title}
        onMinimize={() => console.log("Minimize")}
        onMaximize={() => console.log("Maximize")}
        onClose={onClose}
      />
      <div className="window-content">
        <div className="list-container above-footer">{children}</div>
      </div>
    </div>
  </div>
);

const WindowNav = ({ title, onMinimize, onMaximize, onClose }) => (
  <nav className="window-nav">
    <h3>
      <span>{title}</span>
    </h3>
    <div className="buttons">
      {/* <label className="minimize" onClick={onMinimize}></label> */}
      {/* <label className="maximize" onClick={onMaximize}></label> */}
      <label className="close" onClick={onClose}></label>
    </div>
  </nav>
);

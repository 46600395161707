const Icon = ({ label, iconClass, tabIndex = 0, forAttr, onClick }) => (
  <label
    className={`desktop-icon ${iconClass}`}
    tabIndex={tabIndex}
    onClick={onClick}
  >
    <span>{label}</span>
  </label>
);

export default Icon;

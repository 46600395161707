const WindowWelcome = ({ title, children, onClose,...props }) => (
  <div
    id="window-welcome"
    className="window"
    {...props}
  >
    <div className="window-inside">
      <nav className="window-nav">
        <h3>
          <span>{title}</span>
        </h3>
        <div className="buttons">
          <label
            className="close"
            htmlFor="welcome"
            tabIndex="0"
            onClick={onClose}
          ></label>
        </div>
      </nav>
      <div className="window-content">
        <div className="information-container">
          <div className="information">{children}</div>
        </div>
      </div>
      <div className="window-footer">
        <label
          className="button"
          htmlFor="welcome"
          tabIndex="0"
          onClick={onClose}
        >
          <span>Zamykaj to</span>
        </label>
      </div>
    </div>
  </div>
);

export default WindowWelcome;
